<template>
  <section class="payment-output-page">
    <b-container>
      <b-row>
        <b-col lg="12">
          <h3>{{ $t("payment_success_title") }}</h3>
          <div class="order-number">
            {{ $t("success_order_number") }}: {{ "#" + transactionId }}
          </div>
          <div class="note">
            <label> {{ $t("payment_success_msg") }}</label>
          </div>
          <div class="continue">
            <b-link to="/">
              <b-icon icon="chevron-left"></b-icon>
              {{ $t("continue_shopping") }}</b-link
            >
          </div>
          <!-- <div class="email">
            <label>E-mail adres: example@email.com</label>
          </div> -->
          <b-link class="c-btn c-btn-green" to="/account" v-if="!isLoggedIn">{{
            $t("account_create")
          }}</b-link>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { getSuccessOrder } from "@storefront/core/data-resolver/cart";
import config from "@storefront/core/lib/utils/config";

export default {
  name: "PaymentSuccess",
  components: {},
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("payment_success") },
        { name: "keywords", content: this.$t("payment_success") },
        { name: "description", content: this.$t("payment_success") },
      ],
      title: this.$t("payment_success"),
    };
  },
  computed: {
    transactionId() {
      if (typeof this.$store.state.route.query.transactionid != "undefined") {
        return this.$store.state.route.query.transactionid;
      }
      return null;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    showCustomerEmail() {
      if (config.showCustomerEmail !== undefined) {
        return config.showCustomerEmail;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    const bcrumb = { current: "success", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    if (this.transactionId != null) {
      if (this.$gtm.enabled() == true) {
        const order = await getSuccessOrder(this.transactionId);
        if (order != false) {
          const items = [];
          const prods = [];
          order.items.forEach((element) => {
            let item = {};
            let prod = {};
            item.id = element.product_sku;
            item.name = element.product_name;
            item.price = element.product_sale_price.value;
            item.quantity = element.quantity_ordered;
            prod.item_id = element.product_sku;
            prod.item_name = element.product_name;
            prod.price = element.product_sale_price.value;
            prod.quantity = element.quantity_ordered;
            items.push(item);
            prods.push(prod);
          });
          if (!this.$gtm.uaDisabled) {
            //GA event
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                currencyCode: "EUR",
                purchase: {
                  actionField: {
                    id: this.transactionId,
                    affiliation: config.app_name,
                    revenue: order.total.subtotal.value,
                    tax: order.total.total_tax.value,
                    shipping: order.total.total_shipping.value,
                  },
                  products: items,
                },
              },
              email: this.showCustomerEmail ? order.customer_email : "",
            });
          }
          if (this.$gtm.ga4Enabled) {
            //GA4 event
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                add: {
                  transaction_id: this.transactionId,
                  affiliation: config.app_name,
                  value: order.total.subtotal.value,
                  tax: order.total.total_tax.value,
                  shipping: order.total.total_shipping.value,
                  items: prods,
                },
              },
              email: this.showCustomerEmail ? order.customer_email : "",
            });
          }
        }
      }
    }
  },

  data: () => ({}),
};
</script>

<style lang="scss" scoped>
</style>
